// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-authorship-page-js": () => import("./../../../src/templates/authorship-page.js" /* webpackChunkName: "component---src-templates-authorship-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-faqs-page-js": () => import("./../../../src/templates/faqs-page.js" /* webpackChunkName: "component---src-templates-faqs-page-js" */),
  "component---src-templates-help-page-js": () => import("./../../../src/templates/help-page.js" /* webpackChunkName: "component---src-templates-help-page-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-our-principles-page-js": () => import("./../../../src/templates/our-principles-page.js" /* webpackChunkName: "component---src-templates-our-principles-page-js" */),
  "component---src-templates-our-team-page-js": () => import("./../../../src/templates/our-team-page.js" /* webpackChunkName: "component---src-templates-our-team-page-js" */),
  "component---src-templates-peer-review-page-js": () => import("./../../../src/templates/peer-review-page.js" /* webpackChunkName: "component---src-templates-peer-review-page-js" */),
  "component---src-templates-privacy-page-js": () => import("./../../../src/templates/privacy-page.js" /* webpackChunkName: "component---src-templates-privacy-page-js" */),
  "component---src-templates-proposal-book-series-page-js": () => import("./../../../src/templates/proposal-book-series-page.js" /* webpackChunkName: "component---src-templates-proposal-book-series-page-js" */),
  "component---src-templates-proposal-books-page-js": () => import("./../../../src/templates/proposal-books-page.js" /* webpackChunkName: "component---src-templates-proposal-books-page-js" */),
  "component---src-templates-proposal-journals-page-js": () => import("./../../../src/templates/proposal-journals-page.js" /* webpackChunkName: "component---src-templates-proposal-journals-page-js" */),
  "component---src-templates-reports-page-js": () => import("./../../../src/templates/reports-page.js" /* webpackChunkName: "component---src-templates-reports-page-js" */),
  "component---src-templates-research-integrity-page-js": () => import("./../../../src/templates/research-integrity-page.js" /* webpackChunkName: "component---src-templates-research-integrity-page-js" */),
  "component---src-templates-terms-page-js": () => import("./../../../src/templates/terms-page.js" /* webpackChunkName: "component---src-templates-terms-page-js" */)
}

